var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "carousel",
      on: { mouseover: _vm.pause, mouseleave: _vm.startSlide }
    },
    [
      _c("div", { staticClass: "slide", class: _vm.currentSlide.bg }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "content" }, [
            _c("h2", {
              domProps: { innerHTML: _vm._s(_vm.currentSlide.heading) }
            }),
            _vm._v(" "),
            _c("p", { domProps: { innerHTML: _vm._s(_vm.currentSlide.body) } }),
            _vm._v(" "),
            _c(
              "a",
              { staticClass: "button", attrs: { href: _vm.currentSlide.link } },
              [_vm._v("Learn More")]
            )
          ]),
          _vm._v(" "),
          _c("img", {
            attrs: {
              src: _vm.currentSlide.image,
              alt: _vm.currentSlide.heading
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "prev", attrs: { href: "#" }, on: { click: _vm.prev } },
        [_c("i", { staticClass: "fas fa-chevron-left" })]
      ),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "next", attrs: { href: "#" }, on: { click: _vm.next } },
        [_c("i", { staticClass: "fas fa-chevron-right" })]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }