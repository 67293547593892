/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

//require('./bootstrap');

window.Vue = require('vue');

//Import Vue Components
import Datepicker from 'vuejs-datepicker';
import { Slide } from 'vue-burger-menu';  // import the CSS transitions you wish to use, in this case we are using `Slide`

//Register Components
Vue.component('slider', require('./components/Carousel.vue').default);
Vue.component('accordion', require('./components/Accordion.vue').default);
Vue.component('dropdown', require('./components/Dropdown.vue').default);
Vue.component('accordion-menu', require('./components/AccordionMenu.vue').default);

//Shop Components
Vue.component('checkout-form', require('./components/Shop/CheckoutForm.vue').default);

const app = new Vue({
    el: '#app',
    components: {
	    Datepicker,
	    Slide,
	},
});