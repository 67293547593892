<template>
  <section class="carousel"
    @mouseover="pause"
    @mouseleave="startSlide"
  >
    <div class="slide" :class="currentSlide.bg">
      <div class="container">
        <div class="content">
          <h2 v-html="currentSlide.heading"></h2>
          <p v-html="currentSlide.body"></p>
          <a class="button" :href="currentSlide.link">Learn More</a>
        </div>
        <img :src="currentSlide.image" :alt="currentSlide.heading" />
      </div>
    </div>

    <span class="prev" @click="prev" href="#"><i class="fas fa-chevron-left"></i></span>
    <span class="next" @click="next" href="#"><i class="fas fa-chevron-right"></i></span>
  </section>
</template>

<script>
export default {
  data() {
    return {
      slides: [
        {
          heading: 'Change Cycle&trade; Training',
          body: '<h4><i>Please see new e-Learning and virtual training options!</i></h4>Change is not a workplace event that has a beginning, middle and end. Change Cycle&trade; training is a proven way to give people perspective and specific \'how-to\' skills to take personal responsibility for their productivity every day.',
          image: 'storage/files/shares/pages/slide-changeatwork.png',
          link: '/training',
          bg: 'yellow'
        },
        {
          heading: 'The Locator&trade; Assessment Profile!',
          body: 'Whether you are experiencing a difficult time in your life or trying to manage your team through a series of changes at work, The Locator&trade; pinpoints where you are now  within the 6 stages of The Change Cycle&trade;',
          image: 'storage/files/shares/pages/slide-elocator.png',
          link: '/the-elocator',
          bg: 'blue'
        },
        {
          heading: 'Whose job is the hardest in organizational change?',
          body: 'Supervisors and frontline managers have the toughest job because they are the front line for handling all the emotion and distraction when workers are in the thick of a difficult change.',
          image: 'storage/files/shares/pages/slide-question.png',
          link: '/training',
          bg: 'green'
        },
        {
          heading: 'What Makes the Change Cycle&trade; Relevant?',
          body: 'There\'s plenty of research from fields of psychology, neurology, physiology, even physics to assist in the study of the human experience of change. Here are the condensed findings in an easily understood and practical visual – The Change Cycle&trade; model.',
          image: 'storage/files/shares/pages/slide-stack-of-files.png',
          link: '/change-cycle',
          bg: 'red'
        },
      ],
      timer: null,
      currentIndex: 0,
    };
  },

  mounted: function() {
    this.startSlide();
  },

  methods: {
    startSlide: function() {
      this.timer = setInterval(this.next, 6000);
    },

    next: function() {
      this.currentIndex += 1;
    },
    prev: function() {
      this.currentIndex -= 1;
    },
    pause: function(){
      clearInterval(this.timer);
    }
  },

  computed: {
    currentSlide: function() {
      return this.slides[Math.abs(this.currentIndex) % this.slides.length];
    }
  }
};
</script>