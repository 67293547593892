var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dropdown-item", class: _vm.classes }, [
    _c(
      "a",
      {
        staticClass: "dropdown-title",
        on: {
          click: function($event) {
            _vm.show = !_vm.show
          }
        }
      },
      [
        _vm._t("title"),
        _vm._v(" "),
        _vm.show
          ? _c("i", {
              staticClass: "fas fa-chevron-up",
              attrs: { "aria-hidden": "true" }
            })
          : _c("i", {
              staticClass: "fas fa-chevron-down",
              attrs: { "aria-hidden": "true" }
            })
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show == true,
            expression: "show == true"
          }
        ],
        staticClass: "dropdown-pane"
      },
      [_vm._t("content")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }