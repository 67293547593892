<template>
	<li class="accordion-menu">
    <a class="title" @click="show = !show">
      <span><slot name="title"></slot></span>
      <i class="fas fa-chevron-up" aria-hidden="true" v-if="show"></i>
      <i class="fas fa-chevron-down" aria-hidden="true" v-else></i>
    </a>

    <ul class="is-accordion-submenu" v-show="show == true">
		  <slot name="content"></slot>
	  </ul>
	</li>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    }
  }
};
</script>

<style>
  .accordion-menu{
    .title{
      display: flex;
      justify-content: space-between;
    }
  }
</style>