var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "checkout",
      on: {
        submit: function($event) {
          $event.preventDefault()
        }
      }
    },
    [
      _c("section", [
        _c("header", [
          _c("h1", [_vm._v("Order Details")]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm.errors.length
            ? _c("div", { staticClass: "alert callout" }, [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "ul",
                  _vm._l(_vm.errors, function(error) {
                    return _c("li", [_vm._v(_vm._s(error))])
                  }),
                  0
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "fieldset" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.order.shipping.first_name,
                expression: "order.shipping.first_name"
              }
            ],
            attrs: {
              type: "text",
              name: "first_name",
              placeholder: "First Name*"
            },
            domProps: { value: _vm.order.shipping.first_name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.order.shipping, "first_name", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.order.shipping.last_name,
                expression: "order.shipping.last_name"
              }
            ],
            attrs: {
              type: "text",
              name: "last_name",
              placeholder: "Last Name*"
            },
            domProps: { value: _vm.order.shipping.last_name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.order.shipping, "last_name", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "fieldset" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.order.shipping.email,
                expression: "order.shipping.email"
              }
            ],
            attrs: { type: "email", name: "email", placeholder: "Email*" },
            domProps: { value: _vm.order.shipping.email },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.order.shipping, "email", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.order.shipping.phone,
                expression: "order.shipping.phone"
              }
            ],
            attrs: {
              type: "tel",
              name: "phone_number",
              placeholder: "Phone Number with Area Code*"
            },
            domProps: { value: _vm.order.shipping.phone },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.order.shipping, "phone", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c("input", {
            attrs: {
              type: "text",
              name: "organization_name",
              placeholder: "Organization (Optional)"
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "shipping" }, [
        _vm._m(2),
        _vm._v(" "),
        _c("div", { staticClass: "fieldset" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.order.shipping.address.line_1,
                expression: "order.shipping.address.line_1"
              }
            ],
            attrs: {
              type: "text",
              name: "shipping_address_1",
              placeholder: "Street Address*"
            },
            domProps: { value: _vm.order.shipping.address.line_1 },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.order.shipping.address,
                  "line_1",
                  $event.target.value
                )
              }
            }
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.order.shipping.address.line_2,
                expression: "order.shipping.address.line_2"
              }
            ],
            attrs: {
              type: "text",
              name: "shipping_address_2",
              placeholder: "Suite, Unit, etc..."
            },
            domProps: { value: _vm.order.shipping.address.line_2 },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.order.shipping.address,
                  "line_2",
                  $event.target.value
                )
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "locale fieldset" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.order.shipping.address.city,
                expression: "order.shipping.address.city"
              }
            ],
            staticClass: "city",
            attrs: {
              type: "text",
              name: "shipping_city",
              placeholder: "City*"
            },
            domProps: { value: _vm.order.shipping.address.city },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.order.shipping.address,
                  "city",
                  $event.target.value
                )
              }
            }
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.order.shipping.address.state,
                expression: "order.shipping.address.state"
              }
            ],
            staticClass: "state",
            attrs: {
              type: "text",
              name: "shipping_state",
              placeholder: "State*"
            },
            domProps: { value: _vm.order.shipping.address.state },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.order.shipping.address,
                  "state",
                  $event.target.value
                )
              }
            }
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.order.shipping.address.zip,
                expression: "order.shipping.address.zip"
              }
            ],
            staticClass: "zip",
            attrs: { type: "text", name: "shipping_zip", placeholder: "Zip*" },
            domProps: { value: _vm.order.shipping.address.zip },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.order.shipping.address, "zip", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.order.shipping.address.country,
                expression: "order.shipping.address.country"
              }
            ],
            staticClass: "country",
            attrs: {
              type: "text",
              name: "shipping_country",
              placeholder: "Country*"
            },
            domProps: { value: _vm.order.shipping.address.country },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.order.shipping.address,
                  "country",
                  $event.target.value
                )
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "billing" }, [
        _c(
          "a",
          {
            staticClass: "billing-title",
            on: {
              click: function($event) {
                _vm.order.billing.show = !_vm.order.billing.show
              }
            }
          },
          [
            _c("h2", [_vm._v("Bill to a different address?")]),
            _vm._v(" "),
            _vm.order.billing.show
              ? _c("i", {
                  staticClass: "fas fa-chevron-up",
                  attrs: { "aria-hidden": "true" }
                })
              : _c("i", {
                  staticClass: "fas fa-chevron-down",
                  attrs: { "aria-hidden": "true" }
                })
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.order.billing.show == true,
                expression: "order.billing.show == true"
              }
            ],
            staticClass: "billing-content"
          },
          [
            _c("div", { staticClass: "fieldset" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.order.billing.first_name,
                    expression: "order.billing.first_name"
                  }
                ],
                attrs: {
                  type: "text",
                  name: "billing_first_name",
                  placeholder: "First Name (If Different)"
                },
                domProps: { value: _vm.order.billing.first_name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.order.billing,
                      "first_name",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.order.billing.last_name,
                    expression: "order.billing.last_name"
                  }
                ],
                attrs: {
                  type: "text",
                  name: "billing_last_name",
                  placeholder: "Last Name (If Different)"
                },
                domProps: { value: _vm.order.billing.last_name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.order.billing,
                      "last_name",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "fieldset" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.order.billing.address.line_1,
                    expression: "order.billing.address.line_1"
                  }
                ],
                attrs: {
                  type: "text",
                  name: "billing_address_1",
                  placeholder: "Billing Street Address*"
                },
                domProps: { value: _vm.order.billing.address.line_1 },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.order.billing.address,
                      "line_1",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.order.billing.address.line_2,
                    expression: "order.billing.address.line_2"
                  }
                ],
                attrs: {
                  type: "text",
                  name: "billing_address_2",
                  placeholder: "Suite, Unit, etc..."
                },
                domProps: { value: _vm.order.billing.address.line_2 },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.order.billing.address,
                      "line_2",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "fieldset" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.order.billing.address.city,
                    expression: "order.billing.address.city"
                  }
                ],
                attrs: {
                  type: "text",
                  name: "billing_city",
                  placeholder: "City*"
                },
                domProps: { value: _vm.order.billing.address.city },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.order.billing.address,
                      "city",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.order.billing.address.state,
                    expression: "order.billing.address.state"
                  }
                ],
                attrs: {
                  type: "text",
                  name: "billing_state",
                  placeholder: "State*"
                },
                domProps: { value: _vm.order.billing.address.state },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.order.billing.address,
                      "state",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.order.billing.address.zip,
                    expression: "order.billing.address.zip"
                  }
                ],
                attrs: {
                  type: "text",
                  name: "billing_zip",
                  placeholder: "Zip*"
                },
                domProps: { value: _vm.order.billing.address.zip },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.order.billing.address,
                      "zip",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.order.billing.address.country,
                    expression: "order.billing.address.country"
                  }
                ],
                attrs: {
                  type: "text",
                  name: "billing_country",
                  placeholder: "Country*"
                },
                domProps: { value: _vm.order.billing.address.country },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.order.billing.address,
                      "country",
                      $event.target.value
                    )
                  }
                }
              })
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "order-details" }, [
        _c("h2", [_vm._v("Payment:")]),
        _vm._v(" "),
        _c("div", { staticClass: "fieldset" }, [
          _c("div", { staticClass: "select" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.order.details.payment_method,
                    expression: "order.details.payment_method"
                  }
                ],
                attrs: { name: "payment_method" },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.order.details,
                      "payment_method",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _c(
                  "option",
                  { attrs: { disabled: "" }, domProps: { selected: true } },
                  [_vm._v("Select Payment Method*")]
                ),
                _vm._v(" "),
                _c("option", { attrs: { disabled: "" } }, [_vm._v("---")]),
                _vm._v(" "),
                _c("option", [_vm._v("Credit Card")]),
                _vm._v(" "),
                _c("option", [_vm._v("Invoice")])
              ]
            )
          ]),
          _vm._v(" "),
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.order.details.po_number,
                  expression: "order.details.po_number"
                }
              ],
              attrs: {
                type: "text",
                name: "po_number",
                placeholder: "Purchase Order Number (optional)"
              },
              domProps: { value: _vm.order.details.po_number },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.order.details, "po_number", $event.target.value)
                }
              }
            }),
            _vm._v(
              "\n                Provide within 10 days, will ship out otherwise."
            )
          ])
        ]),
        _vm._v(" "),
        _vm.order.details.payment_method == "Credit Card"
          ? _c("div", { staticClass: "fieldset" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.order.details.cc_number,
                    expression: "order.details.cc_number"
                  }
                ],
                attrs: {
                  type: "text",
                  name: "cc_number",
                  placeholder: "Credit Card Number"
                },
                domProps: { value: _vm.order.details.cc_number },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.order.details,
                      "cc_number",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.order.details.cc_expiration,
                    expression: "order.details.cc_expiration"
                  }
                ],
                attrs: {
                  type: "text",
                  name: "cc_expiration",
                  placeholder: "Expiration Date"
                },
                domProps: { value: _vm.order.details.cc_expiration },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.order.details,
                      "cc_expiration",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.order.details.cc_code,
                    expression: "order.details.cc_code"
                  }
                ],
                attrs: {
                  type: "text",
                  name: "cc_code",
                  placeholder: "3-4 Digit Security Code"
                },
                domProps: { value: _vm.order.details.cc_code },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.order.details, "cc_code", $event.target.value)
                  }
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("h2", [_vm._v("Date Needed:")]),
        _vm._v(" "),
        _c("div", { staticClass: "fieldset" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.order.details.deliver_by,
                expression: "order.details.deliver_by"
              }
            ],
            attrs: {
              type: "date",
              name: "deliver_by",
              placeholder: "Deliver On or Before"
            },
            domProps: { value: _vm.order.details.deliver_by },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.order.details, "deliver_by", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.order.details.training_date,
                expression: "order.details.training_date"
              }
            ],
            attrs: {
              type: "text",
              name: "training_date",
              placeholder: "Training Date"
            },
            domProps: { value: _vm.order.details.training_date },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.order.details,
                  "training_date",
                  $event.target.value
                )
              }
            }
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.order.details.trainer_name,
                expression: "order.details.trainer_name"
              }
            ],
            attrs: {
              type: "text",
              name: "trainer_name",
              placeholder: "Trainer Name"
            },
            domProps: { value: _vm.order.details.trainer_name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.order.details, "trainer_name", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("h2", [_vm._v("Order Notes:")]),
        _vm._v(" "),
        _c("div", { staticClass: "fieldset" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.order.details.notes,
                expression: "order.details.notes"
              }
            ],
            attrs: { name: "order_notes" },
            domProps: { value: _vm.order.details.notes },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.order.details, "notes", $event.target.value)
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button",
          attrs: { type: "submit" },
          on: {
            click: function($event) {
              return _vm.validateForm()
            }
          }
        },
        [_vm._v("Place Order")]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "legend" }, [
      _c("p", [
        _vm._v("Items with an "),
        _c("strong", [_vm._v("*")]),
        _vm._v(" are required.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("i", { staticClass: "fi-alert" }),
      _vm._v(" There are some errors in your form.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [_c("h2", [_vm._v("Shipping Address")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }