<template>
	<form class="checkout" v-on:submit.prevent>
        <section>
            <header>
                <h1>Order Details</h1>
                <div class="legend">
                    <p>Items with an <strong>*</strong> are required.</p>
                </div>
                <div class="alert callout" v-if="errors.length">
                    <p><i class="fi-alert"></i> There are some errors in your form.</p>
                    <ul>
                      <li v-for="error in errors">{{ error }}</li>
                    </ul>
                </div>
            </header>
            <div class="fieldset">
                <input type="text" name="first_name" placeholder="First Name*" v-model="order.shipping.first_name">
                <input type="text" name="last_name" placeholder="Last Name*" v-model="order.shipping.last_name">
            </div>

            <div class="fieldset">
                <input type="email" name="email" placeholder="Email*" v-model="order.shipping.email">
                <input type="tel" name="phone_number" placeholder="Phone Number with Area Code*" v-model="order.shipping.phone">
                <input type="text" name="organization_name" placeholder="Organization (Optional)">
            </div>
        </section>

        <section class="shipping">
            <header><h2>Shipping Address</h2></header>
            <div class="fieldset">
                <input type="text" name="shipping_address_1" placeholder="Street Address*" v-model="order.shipping.address.line_1">
                <input type="text" name="shipping_address_2" placeholder="Suite, Unit, etc..." v-model="order.shipping.address.line_2">
            </div>
            <div class="locale fieldset">
                <input class="city" type="text" name="shipping_city" placeholder="City*" v-model="order.shipping.address.city">
                <input class="state" type="text" name="shipping_state" placeholder="State*" v-model="order.shipping.address.state">
                <input class="zip" type="text" name="shipping_zip" placeholder="Zip*" v-model="order.shipping.address.zip">
                <input class="country" type="text" name="shipping_country" placeholder="Country*" v-model="order.shipping.address.country">
            </div>
        </section>

        <section class="billing">
            <a class="billing-title" @click="order.billing.show = !order.billing.show;">
                <h2>Bill to a different address?</h2>
                <i class="fas fa-chevron-up" aria-hidden="true" v-if="order.billing.show"></i>
                <i class="fas fa-chevron-down" aria-hidden="true" v-else></i>
            </a>
            <div class="billing-content" v-show="order.billing.show == true">
                <div class="fieldset">
                    <input type="text" name="billing_first_name" placeholder="First Name (If Different)"  v-model="order.billing.first_name">
                    <input type="text" name="billing_last_name" placeholder="Last Name (If Different)" v-model="order.billing.last_name">
                </div>
                <div class="fieldset">
                    <input type="text" name="billing_address_1" placeholder="Billing Street Address*" v-model="order.billing.address.line_1">
                    <input type="text" name="billing_address_2" placeholder="Suite, Unit, etc..." v-model="order.billing.address.line_2">
                </div>
                <div class="fieldset">
                    <input type="text" name="billing_city" placeholder="City*" v-model="order.billing.address.city">
                    <input type="text" name="billing_state" placeholder="State*" v-model="order.billing.address.state">
                    <input type="text" name="billing_zip" placeholder="Zip*" v-model="order.billing.address.zip">
                    <input type="text" name="billing_country" placeholder="Country*" v-model="order.billing.address.country">
                </div>
            </div>
        </section>

         <section class="order-details">
            <h2>Payment:</h2>
            <div class="fieldset">
                <div class="select">
                    <select name="payment_method" v-model="order.details.payment_method">
                        <option :selected="true" disabled>Select Payment Method*</option>
                        <option disabled>---</option>
                        <option>Credit Card</option>
                        <option>Invoice</option>
                    </select>
                </div>
                <label>
                    <input type="text" name="po_number" placeholder="Purchase Order Number (optional)" v-model="order.details.po_number">
                Provide within 10 days, will ship out otherwise.</label>
            </div>
            <div class="fieldset" v-if="order.details.payment_method == 'Credit Card'">
                <input type="text" name="cc_number" placeholder="Credit Card Number" v-model="order.details.cc_number">
                <input type="text" name="cc_expiration" placeholder="Expiration Date" v-model="order.details.cc_expiration">
                <input type="text" name="cc_code" placeholder="3-4 Digit Security Code" v-model="order.details.cc_code">
            </div>

            <h2>Date Needed:</h2>
            <div class="fieldset">
                <input type="date" name="deliver_by" placeholder="Deliver On or Before" v-model="order.details.deliver_by">
                <input type="text" name="training_date" placeholder="Training Date" v-model="order.details.training_date">
                <input type="text" name="trainer_name" placeholder="Trainer Name" v-model="order.details.trainer_name">
            </div>

            <h2>Order Notes:</h2>
            <div class="fieldset">
                <textarea name="order_notes" v-model="order.details.notes"></textarea>
            </div>
         </section>

        <button class="button" type="submit" v-on:click="validateForm()">Place Order</button>
    </form>
</template>

<script>
export default {
    data: function () {
        return {
            errors: [],
            order:{
                shipping:{
                    first_name: null,
                    last_name: null,
                    email: null,
                    phone: null,
                    address:{
                        line_1: null,
                        line_2: null,
                        city: null,
                        state: null,
                        zip: null,
                        country: 'USA',
                    }
                },
                billing:{
                    show: false,
                    first_name: null,
                    last_name: null,
                    address:{
                        line_1: null,
                        line_2: null,
                        city: null,
                        state: null,
                        zip: null,
                        country: 'USA',
                    }
                },
                details:{
                    payment_method: 'Select Payment Method*',
                    po_number: null,
                    cc_number: null,
                    cc_expiration: null,
                    cc_code: null,
                    deliver_by: null,
                    training_date: null,
                    trainer_name: null,
                    notes: null,
                },
                items:[],
            },
        };
    },
    methods: {
        validateForm: function (e) {
            
            
            this.createOrder();
        },
        createOrder: function(){
            let self = this;
            let params = Object.assign({}, self.order);
            axios.post('/orders', params)
              /*.then(function(){
                self.item.product = '';
                self.item.quantities = '';
                self.item.locator = '';
                self.item.size = '';
                self.item.location = '';
              })*/
              .catch(function(error){
                console.log(error);
              });
        },
    },
    created() {
        axios.get('/api/checkout/').then(response => {
            let { data } = response;
            this.order.items = data;
        });
    }
}
</script>