<template>
	<div class="accordion-item">
      <a class="accordion-title" @click="show = !show;" v-bind:class="color">
        <span><slot name="summary"></slot></span>
        <i class="fas fa-chevron-up" aria-hidden="true" v-if="show"></i>
        <i class="fas fa-chevron-down" aria-hidden="true" v-else></i>
      </a>

      <div class="accordion-content" v-show="show == true" v-bind:class="color">
		<slot name="content"></slot>
	  </div>
	</div>
</template>

<script>
export default {
  props: ['color'],
  data() {
    return {
      show: false,
    }
  }
};
</script>