<template>
	<div class="dropdown-item"  v-bind:class="classes">
      <a class="dropdown-title" @click="show = !show;">
        <slot name="title"></slot>
        <i class="fas fa-chevron-up" aria-hidden="true" v-if="show"></i>
        <i class="fas fa-chevron-down" aria-hidden="true" v-else></i>
      </a>

      <div class="dropdown-pane" v-show="show == true">
  		  <slot name="content"></slot>
  	  </div>
	</div>
</template>

<script>
export default {
  props: ['classes'],
  data() {
    return {
      show: false,
    }
  }
};
</script>